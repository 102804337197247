import React from 'react';
// import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { registerUser } from '../../../_actions/user_actions';
import { useDispatch } from 'react-redux';

import { Form, Input, Button } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function RegisterPage(props) {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        email: '',
        lastName: '',
        name: '',
        department: '',
        company: '',
        password: '',
        confirmPassword: '',
        groupCode: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('名前（名）を入力してください。'),
        lastName: Yup.string().required('名前（姓）を入力してください。'),
        email: Yup.string()
          .email('メールの形式ではありません。')
          .required('メールを入力してください。'),
        password: Yup.string()
          .min(4, 'パスワードは英数4文字以上です。')
          .required('パスワードを入力してください。'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'パスワードが一致しません。')
          .required('パスワードを再入力してください'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let dataToSubmit = {
            email: values.email,
            password: values.password,
            init_pass: values.password,
            name: values.name,
            lastname: values.lastName,
            department: values.department,
            company: values.company,
            // image: `http://gravatar.com/avatar/${moment().unix()}?d=identicon`,
            group_code: values.groupCode,
          };

          dispatch(registerUser(dataToSubmit)).then((response) => {
            if (response.payload.success) {
              props.history.push('/login');
            } else {
              alert(response.payload.err.errmsg);
            }
          });

          setSubmitting(false);
        }, 500);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return (
          <div className="app">
            <h2>ユーザ登録</h2>
            <Form
              style={{ minWidth: '375px' }}
              {...formItemLayout}
              onSubmit={handleSubmit}
            >
              <Form.Item required label="名前（姓）">
                <Input
                  id="lastName"
                  placeholder="名前（姓）入力"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.lastName && touched.lastName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.lastName && touched.lastName && (
                  <div className="input-feedback">{errors.lastName}</div>
                )}
              </Form.Item>

              <Form.Item required label="名前（名）">
                <Input
                  id="name"
                  placeholder="名前（名）入力"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.name && touched.name
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </Form.Item>

              <Form.Item
                required
                label="メール"
                hasFeedback
                validateStatus={
                  errors.email && touched.email ? 'error' : 'success'
                }
              >
                <Input
                  id="email"
                  placeholder="メール入力"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </Form.Item>
              <Form.Item label="会社名">
                <Input
                  id="company"
                  placeholder="会社名入力（任意）"
                  type="text"
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.company && touched.company
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.company && touched.company && (
                  <div className="input-feedback">{errors.company}</div>
                )}
              </Form.Item>
              <Form.Item label="部署名">
                <Input
                  id="department"
                  placeholder="部署名入力（任意）"
                  type="text"
                  value={values.department}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.department && touched.department
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.department && touched.department && (
                  <div className="input-feedback">{errors.department}</div>
                )}
              </Form.Item>
              <Form.Item
                required
                label="パスワード"
                hasFeedback
                validateStatus={
                  errors.password && touched.password ? 'error' : 'success'
                }
              >
                <Input
                  id="password"
                  placeholder="パスワード入力"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </Form.Item>

              <Form.Item required label="確認パスワード" hasFeedback>
                <Input
                  id="confirmPassword"
                  placeholder="確認パスワードを入力"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="input-feedback">{errors.confirmPassword}</div>
                )}
              </Form.Item>
              <Form.Item label="企業コード">
                <Input
                  id="groupCode"
                  placeholder="企業コード入力（任意）"
                  type="text"
                  value={values.groupCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.groupCode && touched.groupCode
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.groupCode && touched.groupCode && (
                  <div className="input-feedback">{errors.groupCode}</div>
                )}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  disabled={isSubmitting}
                >
                  登録
                </Button>
              </Form.Item>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default RegisterPage;
